import React from 'react';
import styled from '@emotion/styled';
import { formatCurrency } from 'utils/rifmFormatters';
import { getListingAddress } from 'utils/listing';
import { getDateDifference } from 'utils/date';
import { Icon } from '@xchange/uikit';
import AccordionTableItem from 'components/AccordionTableItem';
import ListingPhotoAndAddress from './ListingPhotoAndAddress';

interface ExpirationDateTextProps {
  date: number | null;
}

const ExpirationDateText: React.FC<ExpirationDateTextProps> = ({ date }) => {
  if (!date) return <>-</>;
  const dateDifference = getDateDifference(date, true);
  const expired = Boolean(dateDifference && dateDifference?.diff < 0);

  return (
    <StyledExpirationDateText className={expired ? 'expired' : undefined}>
      {expired ? 'Expired' : dateDifference?.text}
    </StyledExpirationDateText>
  );
};

interface ListingItemSaleProps {
  data: Listing;
}

const ListingItemSale: React.FC<ListingItemSaleProps> = ({ data }) => {
  return (
    <AccordionTableItem
      fields={[
        {
          key: 'address',
          label: 'Address',
          value: <ListingPhotoAndAddress src={data.images?.[0]} address={getListingAddress(data)} />
        },
        {
          key: 'type',
          label: 'Type',
          value: data.propertyType || '-'
        },
        {
          key: 'price',
          label: 'Listing Price',
          value: data.currentPrice ? formatCurrency(data.currentPrice) : '-'
        },
        {
          key: 'date',
          label: 'Listed',
          value: getDateDifference(data.publishDate)?.text || '-'
        },
        {
          key: 'expires',
          label: 'Expires',
          value: <ExpirationDateText date={data.expirationDate} />
        },
        {
          key: 'offers',
          label: 'Offers',
          value: data.offersCount || '-'
        },
        {
          key: 'commissions',
          label: 'Commissions',
          value: data.fullcommissions ? formatCurrency((data.fullcommissions.additionalSaleCommission ? data.fullcommissions.additionalSaleCommission : 0) - (data.fullcommissions.additionalCoopCommission ? data.fullcommissions.additionalCoopCommission : 0) + (data.fullcommissions.marketingFee ? data.fullcommissions.marketingFee : 0) + (data.fullcommissions.mlsFee ? data.fullcommissions.mlsFee : 0) + (((data.fullcommissions.saleCommission ? data.fullcommissions.saleCommission : 0) - (data.fullcommissions.saleCoopCommission && data.dualListing == 0 ? data.fullcommissions.saleCoopCommission : 0)) * data.currentPrice / 100)) : '-'
        },
        {
          key: 'expenses',
          label: 'Expenses',
          value: data.expenses ? formatCurrency(data.expenses) : '-'
        },
        {
          key: 'actions',
          label: '',
          value: data.url && data.mlsStatus !== "Not Linked" && (
            <a className="table-header-link" href={data.url} target="_blank" rel="noreferrer">
              <span>View page</span>&nbsp;
              <Icon name="arrow" size={12} />
            </a>
          )
        }
      ]}
    />
  );
};

export default ListingItemSale;

const StyledExpirationDateText = styled.span`
  &.expired {
    color: ${props => props.theme.colors.red};
  }
`;
